import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Menu from '../components/menu';
import { Link } from 'gatsby';

const AboutPage = () => (
  <Layout>
    <SEO title="404" />
    <header>
      <Menu></Menu>
    </header>
    <section class="page-content">
      <h1 class="section-header">404 - danie, którego szukasz, nie istnieje</h1>
      <p>
        Wygląda na to, że wpis, którego szukasz, nie istnieje pod tym adresem.
        Spróbuj wrócić do <Link url="/">strony głównej</Link>.
      </p>
    </section>
  </Layout>
);

export default AboutPage;
